// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
require("jquery")

Rails.start()
Turbolinks.start()
ActiveStorage.start()

//Import Bootstrap from yarn module. Tambien hay que hacer un @import 'bootstrap' en el scss.
import "bootstrap"
import "chartkick/chart.js"
//Import scss global de la aplicación
import '../src/stylesheets/application.scss'

//import font awesome de modulo yarn
import "@fortawesome/fontawesome-free/css/all"

const images = require.context('../images', true);